import {settings} from './settings';
import '../style/index.less';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './i18n';//for localization
import { createRoot } from 'react-dom/client';
import { AuthComponent } from './components';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import { theme } from '../style/theme';
import { Buffer } from 'buffer';

const container = document.getElementById( 'root' )!;
const root = createRoot( container );

const cacheValue = settings.EnableCsp ? createCache( {
  key:'nonce',
  nonce:Buffer.from( 'secretnoncephilips', 'base64' ).toString( 'base64' ) //eslint-disable-line
} ) : createCache( {key:'philips'} );

root.render( <CacheProvider value={ cacheValue }>
              <BrowserRouter>
               
              <ThemeProvider theme={ theme }>
              <AuthComponent />
              </ThemeProvider>
                
              </BrowserRouter>
            </CacheProvider>);