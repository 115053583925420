import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IUnauthenticatedProps } from '../../types';
/**
 * Renders an error message for unauthorized users
 * @param {IUnauthenticatedProps} props the props required by the component
 * @returns {JSX.Element} the error message component
 */
export const Unauthenticated = ( {configuration,setAuthState}:IUnauthenticatedProps ) => {
  const {t} = useTranslation()
  const [showMessage,setShowMessage] = useState( false );
  const [timer,setTimer] = useState<NodeJS.Timer>()
  useEffect( ()=>{
    if( configuration.authState !== 'logout' ) {
      showMessage && setShowMessage( false );
      setTimer( setTimeout( ()=>setShowMessage( true ),5000 ) );
    }else{
      !showMessage && setShowMessage( true );
    }
    return ()=>{
      timer && clearTimeout( timer );
      setAuthState && setAuthState( 'initial' );
    }
  },[configuration.authState] )
  return (
    showMessage && <h4>
      {t( 'pageErrors.unauthenticated' )}
    </h4> || null
  );
};
