export class SessionModel {
  public channelName: string;
  public channelId: string;    
  public products: ProductModel[] | null;
  public configuration : ConfigurationModel[] |null;
    
  public isICASelection : boolean;
  constructor();    
  constructor( a: string,b: string )
  constructor( a: string,b: string, c :boolean )
  constructor( a: string,b: string, c :boolean, d: ProductModel[] )
  constructor( a?: string, b?: string, c? :boolean, d?: ProductModel[], e? : ConfigurationModel[] ) {
    this.channelName = a ?? '';
    this.channelId = b ?? '';
    this.isICASelection = c ?? false;
    this.products = d ?? [] ;
    this. configuration = e ?? [];        
  }
}

export class ProductModel {
  public code: string;
  public name:string;
  public imagePath:string;
  public configuration : ConfigurationModel[] |null;

  constructor( a: string, b:string, c:string )
  constructor( a: string, b:string, c:string, d? : ConfigurationModel[] ) {
    this.code = a;
    this.name = b;
    this.imagePath = c;
    this. configuration = d ?? [];
  }
}

export class ConfigurationModel {
  public id:string;
  public configurationId:string;
  public isValid:boolean;
  public actionType:string
  public productId : string;
  public productName :string;
  public modifiedOn:string;
  public createdOn:string;
  public isProcessing:boolean = false;
  public countryCode:string;
  public countryName:string;
        
  constructor( configurationId:string, actionType:string, pmDetails:any) {
    this.id = configurationId;
    this.configurationId = configurationId;
    this.isValid = pmDetails.isValid;
    this.actionType = actionType;
    this.productId = pmDetails.productId;
    this.productName = pmDetails.productDescription;
    this.modifiedOn = pmDetails.modifiedOn;
    this.createdOn = pmDetails.createdOn;
    this.countryCode = pmDetails.market != undefined || null ?pmDetails.market.code : "";
    this.countryName = pmDetails.market != undefined || null ?pmDetails.market.name : "";

  }
}