import React, { useState } from 'react';
import { MenuItem, IconButton, Paper, Popper, MenuList, ClickAwayListener, Box } from '@mui/material';
import { IUserPanelProps } from '../../types';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as Constants from '../data/Constants';
import {PopperStyle } from '../../style/Styles';

/**
 * Renders the user name and a menu to logout
 * @param {IUserPanelProps} props the properties for the user panel component
 * @returns {JSX.Element} the user panel
 */

 export const UserPanel = ( { user, onLogout } : IUserPanelProps ) => {
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>( null );
    const {t} = useTranslation();
   
    const anchorRef = React.useRef<HTMLButtonElement>( null );
    const [arrowRef, setArrowRef] = React.useState( null );
    const open = Boolean( anchorElement );
  
    const onInternalLogout = () => {
      onLogout();
    }
    const handleClick = ( event: React.MouseEvent<HTMLElement> ) => {
      setAnchorElement( anchorElement ? null : event.currentTarget );
    };
    const handleClose = ( ) => {
      setAnchorElement( null )
    }
    return <>
      <span color="secondary">{user} </span>
      <IconButton ref={ anchorRef } onClick={ handleClick } sx={ { cursor: 'pointer', color: 'white', padding: '5px' } }>
        <AccountCircleIcon sx={ {height:'1.3em', width:'1.3em'} } /> 
      </IconButton>
      <Popper open={ open } anchorEl={ anchorElement } placement="bottom" role="tooltip" sx={ {zIndex:100} }
        modifiers={ [
          ...Constants.popperModifier,
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            }
          }
        ] }
      >
        <div role="tooltip">
          <Box component="div" id="arrow" data-popper-arrow className="arrow" ref={ setArrowRef } sx={ PopperStyle.arrow }/>
          <Box >
            <Paper >
              <ClickAwayListener onClickAway={ handleClose }>
                <MenuList 
                  autoFocusItem={ open }
                  id="ViewHandler-menu"
                  aria-labelledby="SwitchView-button"
                >
                  <MenuItem onClick={ onInternalLogout } >{t( 'button.logout' )}</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Box>
        </div>
      </Popper>
    </>;
  }