import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import App from '../App';
import { AuthProvider } from 'oidc-react';
import { getConfigData } from '../api/OidcSettings';
import Footer from '../components/Layouts/Footer';
import Header from './Layouts/Header';
import { settings } from '../settings';
import { oidcDataKey } from '../data/Constants';
/**
 * Main component for authentication handling.
 * Skips authentication in developer mode
 * @returns {JSX.Element} the AppRouter component
 */
export const AuthComponent = () => {
  const navigate = useNavigate();

  const [state, setState] = React.useState( {
    configdata: {
      AuthorityUrl: '',
      ClientId: '',
      Redirect: '',
      Redirect_Silent: '',
      Developer_Mode: false,
    },
  } );

  useEffect( () => {
    setState( {
      ...state,
      configdata: getConfigData,
    } );
  }, [] );

  const oidcConfig = {
    onSignIn: async () => {
      
      navigate( {
        pathname: '/'
      } );
           
    },

    authority: state.configdata.AuthorityUrl,
    clientId: state.configdata.ClientId,
    automaticSilentRenew: true,
    grant_type: 'refresh_token',
    response_type: 'code token id_token',
    redirectUri: state.configdata.Redirect,
    silent_redirect_uri: state.configdata.Redirect_Silent,
    scope: 'api://' + state.configdata.ClientId + '/User.Read email openid profile',
    loadUserInfo: false,
    revokeAccessTokenOnSignout: true,
  };
  if ( !oidcConfig.authority ) {
    return null;
  }

  if ( state.configdata.Developer_Mode ) {
    window.sessionStorage.setItem(oidcDataKey, '');

    return<> <Header key="1" Developer_Mode={getConfigData?.Developer_Mode} /><App key="2" /> <Footer key="3" version={settings?.Version} /></>;
  }
  return (
    <OidcApp/>
  );

  function OidcApp()
  {
    window.sessionStorage.setItem(oidcDataKey, 'oidc.user:' + state.configdata.AuthorityUrl + ':'+state.configdata.ClientId)
    return <AuthProvider { ...oidcConfig }>
    <Header key="1" Developer_Mode={getConfigData?.Developer_Mode} /><App key="2" /> <Footer key="3" version={settings?.Version} />
  </AuthProvider>
  }
};
