import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ChannelsComponent } from './Channel/Channels';
import { NoPageFound } from './NoPageFound';
import { DetailsPage } from './Channel/DetailsPage';
import { Unauthenticated } from './Unauthenticated';
import { LandingPage } from './Channel/LandingPage';

function AppRoutes() {

  return (
    <Routes> 
       <Route path="/NotAuthenticated" element={ <Unauthenticated/> } />
       <Route path="/signin/oidc" element={ <ChannelsComponent /> } />
       <Route path="/signin/oidc-silent" element={ <ChannelsComponent /> } />
       <Route path="/" element={<Navigate to="/cva" />} />
        <Route path="" element={<ChannelsComponent/>}>
        <Route path="cva" element={ <LandingPage /> } />       
        <Route path=':channelname/:productId/:configurationId' element={<DetailsPage/>} />
        <Route path='nopagefound' element={<NoPageFound/>} />
        <Route path="*" element={<NoPageFound/>} />
        </Route>
      </Routes>
  );
}

export default AppRoutes;
