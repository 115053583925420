import React, {useState, useEffect} from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { UIDrawer } from '../Layouts/UIDrawer';
import { Grid, Drawer } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { cvaStyles } from '../../../style/Styles';
import { useTranslation } from 'react-i18next';
export const ChannelsComponent = () =>{
  const { channelname, configurationId } = useParams();  
  const [showRightArrowIcon, setShowRightArrowIcon] = useState( false );
  const {t} = useTranslation();
  const toggleDrawer = (value:boolean)=>{
    setShowRightArrowIcon(value);
  }
  const setWindowDimensions = () => {    
    toggleDrawer(false);
  }
  
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])

  const ShowHideDrawer = ()=>{
    if(configurationId == null || configurationId == undefined)
    {
      return <Grid style={{width:"100%"}}>
            <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } xl={12} >
            <Outlet context={[channelname]}/>
            </Grid>      
          </Grid>
    }
    return showRightArrowIcon ? UIDrawerElement(): UIDrawerEmptyElement()
  }

  const UIDrawerEmptyElement = ()=>{    
    return <Grid style={{width:"100%"}}>
    <Grid item xs={ 1 } sm={ 1 } md={ 1 } lg={ 1 } style={{ maxWidth:'50px'}}>      
        <Drawer onClick={ ()=> toggleDrawer(true)} variant="permanent" PaperProps={ { sx: cvaStyles.uiEmptyDrawer.drawer } }>
          <IconButton style={cvaStyles.uiEmptyDrawer.icon} >
            <KeyboardDoubleArrowRightIcon style={{color:"1474A4"}}/>
          </IconButton>
          <span style={cvaStyles.uiEmptyDrawer.navigation}>{t( 'label.navigation' )}</span>
         </Drawer>
    </Grid>
    <Grid item xs={ 11 } sm={ 11 } md={ 11 } lg={ 11 } style={{marginLeft:"50px"}}>
    <Outlet context={[channelname]}/>
    </Grid>      
  </Grid>
  }
  
  const UIDrawerElement = ()=>{
    return <Grid style={{width:"100%"}}>
      <Grid item xs={ 4 } sm={ 2 } md={ 2 } lg={ 2 } style={{ maxWidth: '200px'}}>
      <UIDrawer toggleDrawer={toggleDrawer} />
      </Grid>
      <Grid item xs={ 8 } sm={ 10 } md={ 10 } lg={ 10 } xl={10} style={{marginLeft:"200px"}}>
      <Outlet context={[channelname]}/>
      </Grid>      
    </Grid>
  }

  return ( ShowHideDrawer() )  
}

