import React from 'react';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import channels from '../../data/Channels.json';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { IconButton, Tab, Tabs } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { cvaStyles } from '../../../style/Styles';

const channelSlideFromJson = () => {
  const channelItems: Array<string> = [];
  Object.keys( channels ).forEach( function ( key ) {
    const jsonKey = channels[key];
    Object.keys( jsonKey ).forEach( function ( keyVal ) {
      channelItems.push( {
        channelName: jsonKey[keyVal].name,
        channelId: jsonKey[keyVal].name

      } );
    } );
  } );

  return channelItems;
}

export function UIDrawer({toggleDrawer }: boolean) {
  const [openDialog, setOpenDialog] = React.useState( false );
  const [value, setValue] = React.useState( 0 );  
  const {t} = useTranslation();  

  const onChannelSelection = ( index:number ) => {
    setValue( index);    
    setOpenDialog( true );
  }

  const handleClose = ( confirmation:boolean ) => { 
    setOpenDialog( false );        
    if( !confirmation ) {
      setValue( 1 );
    }
  };

  const itemsList = channelSlideFromJson();

  return (
    <>
      <Dialog
        open={ openDialog }
        onClose={ () =>handleClose( false ) }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid = "dialogBox"
      >
        <DialogTitle id="alert-dialog-title">{t( 'message.channelChange' )}</DialogTitle>
        <DialogActions>
          <Button variant="outlined" size="large" onClick={ () =>handleClose( true ) } autoFocus>Yes</Button>
          <Button variant="outlined" size="large" onClick={ () =>handleClose( false ) }>No</Button>          
        </DialogActions>
      </Dialog>
      <Drawer variant="permanent" id="sideNavpanel" PaperProps={ { sx: { top: '110px', bottom:'25px', width: '200px', display:'flex', height:'auto !important'} } }>
        <div>
        <Grid container>
          <Grid item xs={ 8 } sm={ 8 } md={ 8 } lg={ 8 } >
            <span style={cvaStyles.uiDrawerHeader}>{t( 'label.channels' )}</span>
          </Grid>
          <Grid item xs={ 4 } sm={ 4 } md={ 4 } lg={ 4 } xl={4} >
            <IconButton  onClick={()=> toggleDrawer(false)} style={{marginLeft:"25px"}}>
              <KeyboardDoubleArrowLeftIcon style={{color:"1474A4"}}/>
            </IconButton>
          </Grid>      
        </Grid>

        </div>
        {itemsList.map( ( item, index ) => {
          const { channelName } = item;
          return (
            < Tabs key={ channelName } value={ value } orientation="vertical" variant="scrollable" visibleScrollbar >
              <Tab label={ channelName }
                className="tab-drawer-tabs" iconPosition="end"
                onClick={()=> onChannelSelection(index)}
                value={ index} 
              /> 
            </Tabs>
          )
        } ) }
      </Drawer>
    </>
  );
}
