export const GridCellStyle = {
  configurationId: {
    width: '40%', justifyContent: 'flex-start', color: 'black', textTransform: 'none', fontSize: 18
  },
  cell: { width: '15%', justifyContent: 'flex-center', color: 'black', textTransform: 'none', fontSize: 18 },
  cellTimestamp: { width: '15%', justifyContent: 'flex-center', color: 'black', textTransform: 'none', fontSize: 18 }
};

export const DetailsPageStyle = {
  keyValuePair: {
    marginLeft: 8, display: 'block'
  },
  jsonView: { display: 'flex', height: 390, width: 700, flexDirection: 'column', overflowY: 'scroll' },
  configurationItemStyle:{marginLeft: 5 },
  tableView :{height: 486, width: '88%', marginTop: '1rem', marginLeft: '2rem'},
  productName:{fontSize: '1rem',display: '-webkit-box', color:'#1474A4', fontWeight:'bold', justifyContent: 'flex-start', float:'left'},
  cardMedia:{padding: 1, flexGrow: 2}
}

export const landingPageMainDivStyle = {
  landingBox:{
    borderRadius: 1,    
    borderColor: 'blue',
    paddingLeft:"1rem",
    paddingRight:"1rem",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height:"auto"
  },
  searchButton:{
    display:"flex", 
    justifyContent:"flex-end"
  },
  createButton:{
    marginLeft:10,
    display:"none",
  },
  bottomGrid:{
    display:"flex", 
    justifyContent:"flex-end"
  },
  paper:{
    p: '0.1rem 0.1rem',
    display: 'flex', 
    alignItems: 'center', 
    width: 350,
    paddingBottom:'0.2rem !important'
  },
  searchGrid:{
    display: 'flex', 
    justifyContent: 'flex-end'
  },
  circularProgress:{
    color: "#1976d2", 
    marginLeft: '10px'
  },
  landingPageMainGrid:{
    marginTop:'10px'
  }
}

export const cvaStyles = {  
  uiDrawerHeader:{
    display: "flex",
    marginLeft: "1rem",    
    fontWeight: "bold",
    color: "#00000099",
    fontSize:"18px",
    marginTop:"5px"
  },
  uiEmptyDrawer:{
    drawer:{
      background:"#eeeeee",
      top: '110px',
      bottom:'25px',
      width: '50px',
      display:'flex',
      height:'auto !important',
      justifyContent:"center"
    },
    icon:{
      top:"0",
      position:"absolute",
      left:"0.5rem"
    },
    navigation:{
      fontSize:"1.25rem",
      transform: 'rotate(-90deg)'
    }    
  },
  actionPanel:{
    position: 'absolute',
    left: '0',
    marginBottom:'50px',
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderBottomWidth: 'thin'
  },
  dataGridComponent:{
    width: '100%',
    height:"100%",
    display:'block',
    overflowX: 'hidden',
    overflowY: 'hidden',
    overflow: 'hidden'
  }
}

export const PopperStyle = {
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    color:'red',
   
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      borderStyle: 'solid',
      borderWidth: '0px 1em 1em',
      borderColor: 'transparent transparent rgb(255, 255, 255)',
      marginTop: '-0.4rem'
    }
  }
}
