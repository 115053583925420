import { AppBar, Box, Toolbar } from '@mui/material';
import React from 'react';
import {HeaderLogin} from '../HeaderLogin';
function Header ( Developer_Mode: boolean ) {  
  return(
    <Box sx={ { flexGrow: 1 } }>
      <AppBar position="static" className="headerStyle root">
        <Toolbar className="toolbar">
          <Box
            className="toolbarSection"
            component="img"
            alt="Philips logo."
            src = "../../public/logo.png"
          />  
          <Box>
            <HeaderLogin mode={ Developer_Mode } />
          </Box>
          
        </Toolbar>  
      </AppBar>
    </Box>
  )
}

export default Header;