import { Alert, AlertColor, IconButton, Snackbar } from '@mui/material';
import React, { createContext, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AppRoutes from './AppRoutes';
type SnackDefaultValue = {
    snack: { message: string; severityColor: AlertColor; open: boolean; },
    setSnack: React.Dispatch<React.SetStateAction<{ message: string; severityColor: AlertColor; open: boolean; }>>
  };

export const SnackbarContext = createContext<SnackDefaultValue>( {snack:{message:'context',severityColor:'' as AlertColor,open:false} , setSnack: () => null} );

export const GlobalSnackBar : React.FC = ( )=> {
  const [snack, setSnack] = useState( {
    message: '', severityColor: 'info' as AlertColor,open: false
  } );

  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => {
    if ( reason === 'clickaway' ) {
      return;
    }
    setSnack( {message: '',severityColor: '' as AlertColor,open: false} );
  };
  const myaction = 
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={ handleClose }
      >
        <CloseIcon fontSize="small" />
      </IconButton>
  ;

  return (
    <SnackbarContext.Provider value={ { snack, setSnack } }>
      <Snackbar open={ snack.open } 
        onClose={ handleClose }
        message={ snack.message }
        autoHideDuration={ 5000 }
        action={ myaction }
        anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
      >
        <Alert onClose={ handleClose } severity={ snack.severityColor } sx={ { width: '100%' } }>
          {snack.message}
        </Alert>
      </Snackbar>   
      <AppRoutes />
    </SnackbarContext.Provider>
  );
}