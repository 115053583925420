import { SessionModel, ConfigurationModel} from '../data/SessionModel';
import {appSessionData} from '../data/Constants';
import {ChannelsData} from '../data/ChannelsData';
import {ChannelModel} from '../data/ChannelModel';

export const getFormattedDate = ()=> {
  const date = new Date();
  const month = ( '0' + ( date.getMonth() + 1 ) ).slice( -2 );
  const day = ( '0' + date.getDate() ).slice( -2 );
  const year = date.getFullYear();
  const hour = ( '0' + date.getHours() ).slice( -2 );
  const min = ( '0' + date.getMinutes() ).slice( -2 );
  const seg = ( '0' + date.getSeconds() ).slice( -2 );
  return month + '-' + day + '-' + year + ' ' + hour + ':' + min + ':' + seg;
}

export const addConfigurationToSession = ( configuration: ConfigurationModel )=> {
  //get session
  //parse to object
  //add configs to session
  //stringify the session
  const sessionDetails: string = window.sessionStorage.getItem( appSessionData );  
  let parsedSessionListData: SessionModel = JSON.parse( sessionDetails );
  if(parsedSessionListData == null)
  {
    parsedSessionListData = new SessionModel('','', false, []);
  }
  //Get respective  configuration
  // if same config exists ignore, else add in session
  let appConfiguration = parsedSessionListData.configuration.find((c)=> c.configurationId == configuration.configurationId);
  if(appConfiguration)
  {
    return true;
  }

  parsedSessionListData.configuration.push( configuration )
  window.sessionStorage.setItem( appSessionData, JSON.stringify( parsedSessionListData ) );
  return false;
}

export const updateConfigurationValidation = ( configuration: ConfigurationModel )=> {
  const sessionDetails: string = window.sessionStorage.getItem( appSessionData ); 
      
  const parsedSessionListData: SessionModel = JSON.parse( sessionDetails );
  if( parsedSessionListData == undefined || parsedSessionListData == null ) {
    return;
  }
         
  //add configuration to existing model
  //appConfiguration is reference type of parsedSessionListData
  let appConfiguration = parsedSessionListData.configuration.find((c)=> c.configurationId == configuration.configurationId);
  if(appConfiguration)
  {
    appConfiguration.isValid = configuration.isValid;
    appConfiguration.isProcessing = configuration.isProcessing;
    window.sessionStorage.setItem( appSessionData, JSON.stringify( parsedSessionListData ) );
  }
}

export function getChannelModelDetailsMatchingFromUrl( channelName:string, productId:string ) {        
  //now search and get image and other prods from channels json.
  const channelsList = ChannelsData.channels;
  return channelsList.find( (ch)=> ch.name == channelName)?.models.find((pm)=> pm.code == productId);
}

export function getAllConfigurations() {
  //get configurations from session
  const sessionDetails:string | null| undefined = window.sessionStorage.getItem( appSessionData );
  if( sessionDetails != null ) {
    const parsedSession:SessionModel = JSON.parse( sessionDetails );          
    if( parsedSession?.configuration !=null ) {
      return parsedSession.configuration;
    }
    return new ConfigurationModel( '', false ,'', '' );
  }
  return new ConfigurationModel( '', false, '', '' );
}

export function getChannelProducts( channelName:string ) {        
  const channelsList = ChannelsData.channels;
  return channelsList.find( function ( x:ChannelModel ) {
    return x.name == channelName; 
  } )?.models;
}

export const checkChannelInSession = ( channelName :string )=> {
  //first check Channel in Channel Json 
  const filteredChannel = getChannelDetailsMatchingFromUrl( channelName );
  if( filteredChannel == null || filteredChannel == undefined ) {
    return;
  }
  //get Session data
  const sessionStorageDetails:string = window.sessionStorage.getItem( appSessionData );
  const parsedSessionListData:SessionModel[] = JSON.parse( sessionStorageDetails );
          
  if( parsedSessionListData == undefined || parsedSessionListData == null ) {
    //Add Channel to Session         
    const sessiondetails : SessionModel[] = [];
    sessiondetails.push( new SessionModel( channelName, channelName, true, null ) );
    window.sessionStorage.setItem( appSessionData, JSON.stringify( sessiondetails ) );              
  } else{
    //Update Channel to Session
    parsedSessionListData.push( new SessionModel( channelName, channelName, true, null ) );
    window.sessionStorage.setItem( appSessionData, JSON.stringify( parsedSessionListData ) );
  }      
}

export function getChannelDetailsMatchingFromUrl( channelName:string) {    
  //now search and get image and other prods from channels json.
  let channelsList  = ChannelsData.channels;
  return channelsList.find( function (x:ChannelModel) { return x.name == channelName; });
}

export const checkConfigurationById = ( configurationId: string )=> {
  //check respective channel and model and add the config to it.        
  const sessionDetails: string = window.sessionStorage.getItem( appSessionData );
  if ( sessionDetails == undefined || sessionDetails == null ) {
    return false;
  }
  const parsedSessionListData: SessionModel = JSON.parse( sessionDetails );
  if( parsedSessionListData == undefined || parsedSessionListData == null ) {
    return false;
  }
  //Get respective configuration  
  let appConfiguration = parsedSessionListData?.configuration.find((c)=> c.configurationId == configurationId);
  if(appConfiguration)
  {
    return true;
  }
  return false;
}

export const getConfigurationFromSession = ( configurationId: string)=> {

  //check respective configuration in session
  const sessionDetails: string = window.sessionStorage.getItem( appSessionData );
  if ( sessionDetails == undefined || sessionDetails == null ) {
    return null;
  }
  const parsedSessionListData: SessionModel = JSON.parse( sessionDetails );
  if( parsedSessionListData == undefined || parsedSessionListData == null ) {
    return null;
  }
  //Get respective  configuration
  // if same config exists ignore, else add in session  
  let appConfiguration = parsedSessionListData?.configuration.find((c)=> c.configurationId == configurationId);
  if(appConfiguration)
  {
    return appConfiguration;
  }
  return null;
}

export const formatDateString = (dateValue:string)=> {
  const date = new Date(dateValue);
  const month = ( '0' + ( date.getMonth() + 1 ) ).slice( -2 );
  const day = ( '0' + date.getDate() ).slice( -2 );
  const year = date.getFullYear();
  const hour = ( '0' + date.getHours() ).slice( -2 );
  const min = ( '0' + date.getMinutes() ).slice( -2 );
  const seg = ( '0' + date.getSeconds() ).slice( -2 );
  return month + '-' + day + '-' + year + ' ' + hour + ':' + min + ':' + seg;
}