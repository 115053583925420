import { GridRenderCellParams } from "@mui/x-data-grid";

//HasFromNoOptionsNoProperties
  export function HasFromNoOptionsNoProperties(item: { from: { options: any; properties: any; code: string; } | null; }, params: GridRenderCellParams<any, any, any>){
    if(item.from != null && item.from.options == null && item.from.properties == null)
    {
      if(item.from.code == params.row.featureCode || item.from.code == params.row.featureFamilyCode){
        return {changeType : 'modified', isFound : true}
      }
    }
    return false;
  }
  //NoFromHasToHasOptions
  export function NoFromHasToHasOptions(item: { from: { options: any; code: any; }; to: { options: any; code: any; } | null; }, params: GridRenderCellParams<any, any, any>){
    if(item.from == null && item.to != null && item.to.options != null)
    {
      if(item.to.code == params.row.featureCode || item.to.code == params.row.featureFamilyCode){
        return {changeType : 'modified', isFound : true}
      }
    }
    return false;
  }
  //NoToHasFromHasOptions
  export function NoToHasFromHasOptions(item: { to: { options: any; code: any; }; from: { options: any; code: any; } | null; }, params: GridRenderCellParams<any, any, any>){
    if(item.to == null && item.from != null && item.from.options != null ){
      if(item.from.code == params.row.featureCode || item.from.code == params.row.featureFamilyCode){
        return {changeType : 'modified', isFound : true}
      }
    }
    return false;
  }
  //HasFromHasToHasProperties
  export function HasFromHasToHasProperties(item: { from: { properties: { code: string; }[] | null; code: any; } | null; to: any; }, params: GridRenderCellParams<any, any, any>){
    if(item.from != null && item.to != null && item.from.properties != null){
      if(item.from.code == params.row.featureCode || item.from.code == params.row.featureFamilyCode){
        var optProp = item.from.properties.find(p => p.code == 'OPT');
        if(optProp && optProp.code == 'OPT'){
          return {changeType : 'modified', isFound : true}
        }
      }
    }
    return false;
  }
  
  //RemovedItemFeatureFamilyCode
  export function RemovedItemFeatureFamilyCode(removedItem: { code: any; }, params: GridRenderCellParams<any, any, any>){
    if(removedItem.code == params.row.featureCode || removedItem.code == params.row.featureFamilyCode){
      return {changeType : 'deleted', isFound : true}
    }
    return false;
  }