export const appSessionData = 'appSessionData';
export const displayDefaultChannel = 'default';
export const icaUrl = 'configurationdetails';
export const noPageFound = "nopagefound";
export const detailsPage = "details";
export const oidcDataKey = 'oidcDataKey';
export const punchoutApiUrls = {
    create:"/punchout/v1/configuration/create",
    clone : "/punchout/v1/configuration/clone",
    validate:"/punchout/v1/configuration/validate",
    read:"/punchout/v1/configuration/read/flat",
    search: "/punchout/v1/configuration/search",
    diff : "/punchout/v1/configuration/diff",
    readImage : "/product/v1/images/read",
    getUser: "/user/v1/details"
}

export const popperModifier = [
    {
      name: 'flip',
      enabled: false,
      options: {
        altBoundary: false,
        rootBoundary: 'viewport',
      },
    },
    {
      name: 'preventOverflow',
      enabled: true,
      options: {
        altAxis: true,
        altBoundary: true,
        tether: true,
        rootBoundary: 'document',
      },
    },
  ] 
  export enum EUrlParams {
    ConfigurationId = 'cid',
    Scope = 'cc'
  }