import IcaDataService from '../services/IcaDataService';
import {ConfigurationHeaderModel, CreateApiModel} from '../data/IcaModel';
import {punchoutApiUrls} from '../data/Constants';
export const CreateConfigurationApi=(config:ConfigurationHeaderModel)=>
{
    let createApidata = new CreateApiModel(config);
    let stringifiedModel = JSON.stringify(createApidata);
    return IcaDataService.create(punchoutApiUrls.create, stringifiedModel);
}

export const ValidateConfigurationApi=(configurationId : string)=>
{  
    let urlParam = "?configurationId=" + configurationId;
    return IcaDataService.getByParam(punchoutApiUrls.validate, urlParam);
}

export const ValidateAllConfigurationApi=(configurationId : string)=>
{  
    let urlParam = "?configurationId=" + configurationId;
    return IcaDataService.getByParam(punchoutApiUrls.validate, urlParam);
}

export const CloneConfigurationApi=(data :object)=>
{     
    return IcaDataService.post(punchoutApiUrls.clone , data);
}

export const ReadConfigurationApi=(configurationId : string, data :any)=>
{
    let urlParam = "?configurationId=" + configurationId;
    return IcaDataService.post(punchoutApiUrls.read + urlParam, data);
}
export const SearchConfigurationApi=(data :object)=>
{
    return IcaDataService.post(punchoutApiUrls.search , data);
}
export const DiffPackageVersionApi=(data :object)=>
{
    return IcaDataService.post(punchoutApiUrls.diff , data);
}
export const ReadProductImageApi=(prductCode : string)=>
{  
    let packagePath = "C"+prductCode+"/R"+prductCode;
    let urlParam = "?packagePath="+packagePath+"&productModel="+prductCode;
    return IcaDataService.getByParam(punchoutApiUrls.readImage, urlParam);
}
export const GetUserDetailsApi=()=>
{
    return IcaDataService.getAll(punchoutApiUrls.getUser);
}