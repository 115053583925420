import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import {settings} from '../../settings';
import { useTranslation } from 'react-i18next';
import TermsAndCondition from '../Channel/TermsAndCondition';

export default function Footer() {
  const {t} = useTranslation();
  const color:any = {
    Dev  : 'bg-yellow',
    Prod : 'bg-gray',
    Test : 'bg-orange',
    Demo : 'bg-green',

  };
  const [value, setValue] = React.useState( '' );
  const getVersion = () => {
    return fetch( '../public/Versioninfo.json' ).then( ( response ) =>
      response.json()
    );
    
  };
  useEffect( () => {
    getVersion().then( ( data ) => {
      setValue( Array.isArray( data ) ? data[0].Version : data.Version );
    } );
  }, [] );
  return (
    <Grid className={ `footer root ${color[settings.Environment]}` } >
      <Grid className="env">
        <span >{t( 'footer.env' )}: {settings.Environment}</span>
      </Grid> 
      <Grid className="termsAndConditionStyle">
        <TermsAndCondition/>
        &nbsp;&nbsp;
        <span >{t( 'footer.version' )}:{value}</span>  
      </Grid>
    </Grid>
  );
}

