import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function TermsAndCondition() {
  const [open, setOpen] = React.useState( false );
  const {t} = useTranslation();
  const handleClickOpen = () => {
    setOpen( true );
  };
  const handleClose = () => {
    setOpen( false );
  };

  return (
    <div>
      <div onClick={ handleClickOpen }>
        <span className="title">
          {t( 'footer.termsAndConitons' )}
        </span>
      </div>
      <Dialog className="common-dialog-style termsAndCondition-dialog" open={ open } PaperProps={ {className:'paperClass'} }>
        <DialogTitle className="header">
          {t( 'footer.termsAndConitons' )}
          <IconButton
            aria-label="close"
            onClick={ handleClose }
            className="closeIcon"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent >
          {t( 'footer.termsAndConitons' )}
        </DialogContent>
        <DialogActions className="dialogAction">
          <Button variant="contained">{t( 'button.ok' )}</Button>
          <Button variant="contained" aria-label="close" onClick={ handleClose }>{t( 'button.cancel' )}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
