import React, { useEffect, useState,useContext } from 'react';
import { Grid, Divider,AlertColor } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAllConfigurations, updateConfigurationValidation } from '../../utility/SessionUtil';
import DataTableGrid from './DataTableGrid';
import Box from '@mui/material/Box';
import { landingPageMainDivStyle } from '../../../style/Styles'
import ActionPanel from '../Layouts/ActionPanel';
import { useAuth } from 'oidc-react';
import {GetUserDetailsApi} from '../../api/IcaApi';
import { SnackbarContext } from '../GlobalSnackBar';
import { useTranslation } from 'react-i18next';
export function LandingPage() {
  const auth = useAuth();
  const token = auth.userData?.access_token;
  const navigate = useNavigate();  
  const [dataGridRows, setDataGridRows] = useState( [] );
  const [isDataGridRowEmpty, setIsDataGridRowEmpty] = useState( false );  
  const [screenHeight, setScreenHeight] = useState( 0 );  
  const { setSnack } = useContext( SnackbarContext );
  const {t} = useTranslation();
  useEffect( () => {
    setConfigurationsDataFromSession()
    setScreenHeight(window.innerHeight);
  }, [screenHeight] );
  
  useEffect( () => {
    if( token ) {
      getUserDetails();
    }
  }, [token] )
  function getUserDetails() {
    GetUserDetailsApi()
      .then()
      .catch( function ( e ) {
        console.error( e );
        setSnack( { severityColor:'error' as AlertColor, message: t( 'message.userCreationFailuerMessage' ), open: true} )
      });
  }
  function setConfigurationsDataFromSession() {    
    setDataGridRows( getAllConfigurations() );
    setIsDataGridRowEmpty(enableDisableRefreshButton(dataGridRows));
  }

  function disableSpinnersOnDatGrid()
  {
    var _gridData = getAllConfigurations();
    if(_gridData == null || _gridData.length == null)
    {
      return null;
    }    

    for( const configItem of _gridData ) {
      configItem.isProcessing = false;
      updateConfigurationValidation( configItem );
    }
    return _gridData;
  }
  const location = useLocation();
  useEffect(() => {
    setConfigurationsDataFromSession()
    disableSpinnersOnDatGrid();
  }, [location.key]);

  return (
    <Box sx={ landingPageMainDivStyle.landingBox } >
      <Box className="secondaryHeader">
        <ActionPanel setConfigurationsDataFromSession={ setConfigurationsDataFromSession }
          isDataGridRowEmpty={ isDataGridRowEmpty }
          dataGridRows={ dataGridRows }
          isFromLandingPage={true} />
      </Box>
      <Divider className="divider" />
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } >
          <div style={landingPageMainDivStyle.landingPageMainGrid} >
            <DataTableGrid
              navigate={ navigate }
              dataGridRows={ dataGridRows }
              setConfigurationsDataFromSession={ setConfigurationsDataFromSession }
            /></div>
        </Grid>       
      </Grid>    
    </Box>   
  )
}

function enableDisableRefreshButton(dataGridRowLength:number)
{
  return dataGridRowLength === 0 ? true:false;
}
