import { Grid, IconButton, TextField ,AlertColor} from '@mui/material';
import { Box } from '@mui/system';
import { GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import React, {useContext, useEffect, useState } from 'react';
import { useLocation} from 'react-router-dom';
import {cvaStyles, landingPageMainDivStyle} from '../../../style/Styles'
import { TFunction, useTranslation } from 'react-i18next';
import {ReadConfigurationApi,DiffPackageVersionApi} from '../../api/IcaApi';
import { IProductModelDetails } from 'data/ProductModelDetails';
import ActionPanel from '../Layouts/ActionPanel';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { getConfigurationFromSession } from '../../utility/SessionUtil';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { SnackbarContext } from '../GlobalSnackBar';
import { HasFromNoOptionsNoProperties, NoFromHasToHasOptions, NoToHasFromHasOptions, 
  HasFromHasToHasProperties, RemovedItemFeatureFamilyCode} from './DetailsPageHelper';

function DisplayTableView( {pmDetails, diffResponse }:any) {
  const {t} = useTranslation();

  const [searchText, setSearchText] = React.useState( '' );

  function getOptions(params: { row: { featureCode: string; feature: string; }; }) {
    
    return params.row.featureCode != '' ? params.row.featureCode + ' (' + params.row.feature + ')' : '';
  }

  const columns: GridColDef[] = [
    {
      field: 'featureFamilyCode',flex: 1, headerName: t( 'label.featureCode' ), minWidth: 100,
      renderCell:( params )=>{
        return (params.row.featureFamily != '' ?
         params.row.featureFamilyCode + ' (' + params.row.featureFamily + ')' :
         params.row.featureFamilyCode
     )},
    },  
    {
      field: 'optionCode',
      flex: 1,
      headerName: t( 'label.optionCode' ),minWidth: 100,
      valueGetter: getOptions,
    },{
      field: 'Action',
      flex: .25,
      headerName: '',minWidth: 10,
      renderCell: ( params) => {
        return  showModifiedDetails( params, diffResponse,t);
      }, 
    }
  ];

  const handleSearch = ( value: string ) =>{
    setSearchText( value )
  }

  const clearSearch = ( ) =>{
    setSearchText( '' )
  }
  const   dataGridComponent = {
    width: '90%',    
    height:window.innerHeight,
    display:'flow-root',
    overflowX: 'hidden',
    overflowY: 'auto',    
    marginTop: '1rem', 
    marginLeft: '4rem',
    justifyContent: 'center'
  };
  return (
    
    <Grid item xs={ 10 } md={ 12 } >  
      <Box className="actionBarFlex">
        <TextField
          variant="standard"
          sx={ { m:1, width: 250, display: 'flex', marginRight:4} }
          onChange={ e => handleSearch( e.target.value ) }
          placeholder={ t( 'button.search' ) }
          value={ searchText }
          InputProps={ {
            startAdornment: <SearchIcon />,
            endAdornment: 
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={ { visibility: searchText ? 'visible' : 'hidden' } }
                onClick={ clearSearch }
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ,
          } }
        />
      </Box>
      <Box sx={dataGridComponent}>
        
        <DataGrid
          rows={ rowValues(pmDetails, diffResponse) }
          columns={ columns }
          rowsPerPageOptions={ [] }
          headerHeight={ 36 }
          rowHeight={ 26 }
          hideFooter={ true }
          autoHeight={ true }
          disableColumnMenu
          getCellClassName={ ( params: GridCellParams<number> ) => {
            return getCellClassName(params, searchText);
          } }

          sx={ {
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#eeeeee',
              color: '#000000'
            },
            '& .MuiDataGrid-columnSeparator':{
              display:'none'
            },
            '& .MuiDataGrid-columnHeaderTitle':{ 
              fontSize: '1.25rem', fontWeight: 'normal'},
            '& .MuiDataGrid-cell':{
              display: 'block',
              textOverflow: 'ellipsis',
            },
            '& .MuiDataGrid-virtualScrolle': {
              marginTop: "0 !important"
            },
            lineHeight:'normal',
            '& .highlight': {
              backgroundColor: '#b2dff5',
              color: '#1a3e72',
              fontWeight: '560',
            },
            '& .addedFetureStyle' :{
              backgroundColor: '#D3D3D3'
            }
          } }
        />
      </Box>
    </Grid>
  )
}

function getCellClassName(params: GridCellParams<number, any, number>, searchText: string){
    if(params.row.added && searchText === ''){
      return 'addedFetureStyle'
    }  
    if( params == null || searchText === '' ) {
      return '';
    }

    if( params?.field === 'featureFamilyCode' ) {
      let featureColumn = params.row.featureFamilyCode + ' (' + params.row.featureFamily + ')';
      return returnRowCSS(featureColumn,searchText,params.row.added);
    }

    if( params?.field === 'optionCode' ) {
      let optionColumn = params.row.featureCode + ' (' + params.row.feature + ')';
      return returnRowCSS(optionColumn,searchText,params.row.added );
    }
    return '';
}
function returnRowCSS(text:string, searchText: string, rowAdded:boolean){
   if(text?.toLowerCase().includes( searchText.toLowerCase() )){
    return 'highlight'
  }else if(rowAdded){
    return 'addedFetureStyle'
  }
  return '';
}
const rowValues = ( pmDetails:IProductModelDetails, diffResponse: { added: { options: any[] | null; code: any; value: any; }[]; } | null )=>{
  const pmDetailsJson = JSON.parse( pmDetails );
  var rows: any[] = [];
  
  for ( let i = 0, l = pmDetailsJson.configurationItems.length; i < l; i++ ) {
    rows.push( {
      id: i + 1,
      featureFamilyCode: pmDetailsJson.configurationItems[i].variableName,
      featureFamily: pmDetailsJson.configurationItems[i].variableDescription,
      featureCode: pmDetailsJson.configurationItems[i].value,
      feature: pmDetailsJson.configurationItems[i].valueDescription,
      added : false
    } );
  }
  if(diffResponse == null || diffResponse.added.length <=0){
    return rows;
  }
  
  var count=pmDetailsJson.configurationItems.length;
  diffResponse.added.forEach(function(item: { options: any[] | null; code: any; value: any; }){
        if(item.options != null && item.options.length>0){
          item.options.forEach(function(option){
            rows.push( {
              id: count + 1,
              featureFamilyCode: item.code,
              featureFamily: item.value,
              featureCode: option.code,
              feature:option.value,
              added: true
            } );
            count++;
          })
          
        }else {
          rows.push( {
            id: count + 1,
            featureFamilyCode: item.code,
            featureFamily: item.value,
            featureCode: '',
            feature: '',
            added: true
          } );
          count++;
        }
        
  })
  return rows;
}

export function DetailsPage( {setConfigurationsDataFromSession, isDataGridRowEmpty} ) {
  const configState = useLocation();
  const configurationId = configState.state.configId;
  const [isTableShown, setTableIsShown] = useState( false );
  const [detailsApiResponse, setDetailsApiResponse] = React.useState( [] );
  const [diffResponse, setDiffResponse] = React.useState();
  const [circularProgress, setCircularProgress] = useState( false );
  const { setSnack } = useContext( SnackbarContext );
  const {t} = useTranslation();
  function getDiffPackageDetails(readData){
    
    const diffFormData = {
      'configurationId': String(configurationId)
    };
    DiffPackageVersionApi(diffFormData)
      .then(function(response){
        setDiffResponse(response.data);
        setDetailsApiResponse( readData );
        setCircularProgress(false);
        setTableIsShown( true );
      }).catch( function(e){
        console.error( e );
        setSnack( { severityColor:'error' as AlertColor, message: t( 'message.diffError' ), open: true} )
        setDetailsApiResponse( readData );
        setCircularProgress(false);
        setTableIsShown( true );
        
      } );
  }
  function getConfigurationDetails() {
    var configDetails = getConfigurationFromSession(configurationId);
    setCircularProgress(true);
    const bodyFormData = new FormData();
    bodyFormData.append( 'configurationId', String( configurationId ) );
    ReadConfigurationApi( String( configurationId ), bodyFormData )
      .then( function ( response ) {
        if(configDetails?.isValid){
          setDetailsApiResponse( response.data);
          setCircularProgress(false);
          setTableIsShown( true );
        }else{
          getDiffPackageDetails(response.data);
        }
        
      } )
      .catch( function ( e ) {
        console.error( e );
        setSnack( { severityColor:'error' as AlertColor, message: t( 'message.error' ), open: true} )
      } );
  }
 
  useEffect( ()=>{   
    getConfigurationDetails();
  },[detailsApiResponse] )

  return (
    <div style={ landingPageMainDivStyle.landingBox } >
      {dataGridCircularProgress(circularProgress)}
      <Box className="secondaryHeader" style={ cvaStyles.actionPanel }>
        <ActionPanel setConfigurationsDataFromSession={ setConfigurationsDataFromSession }
          isDataGridRowEmpty={ isDataGridRowEmpty }
          isFromLandingPage={ false }
        />        
      </Box>   
      { isTableShown && <DisplayTableView pmDetails={ detailsApiResponse } diffResponse = {diffResponse}/>} 
    </div >
  )
}

function GetTitleTooltip(isFound: boolean, changeType: string, t: TFunction<"translation", undefined>){
  if(isFound && changeType.toLocaleLowerCase() == 'deleted') // deleted
    return <Tooltip PopperProps={{disablePortal: true }} title={ t( 'tooltip.deleted' ) } placement='bottom'><RemoveCircleOutlineIcon sx={{ color: 'red' }}/></Tooltip>
  else if(isFound && changeType.toLocaleLowerCase() == 'modified') // modified
    return <Tooltip PopperProps={{disablePortal: true }} title={ t( 'tooltip.modified' ) } placement='bottom'><BorderColorIcon sx={{ color: 'grey' }}/></Tooltip>
  else  if(isFound && changeType.toLocaleLowerCase() == 'added') //added
    return <Tooltip PopperProps={{disablePortal: true }} title={ t( 'tooltip.added' ) } placement='top'><AddCircleOutlineIcon sx={{ color: 'green' }}/></Tooltip>
  else
    return <></>
}

function showModifiedDetails( params: GridRenderCellParams<any, any, any>, diffResponse: { updated: any[]; removed: any[]; }, t: TFunction<"translation", undefined>) {
  try {
    if(params.row.added)
      return <Tooltip PopperProps={{disablePortal: true }} title={ t( 'tooltip.added' ) } placement='top'><AddCircleOutlineIcon sx={{ color: 'green' }}/></Tooltip>
    
    var isFound = false;
    var changeType ='';
    
    diffResponse.updated.forEach(function(item){
      //case-1 varibale modfied-name
      const check1 = HasFromNoOptionsNoProperties(item, params);
      if(check1){
        changeType = check1.changeType;
        isFound = check1.isFound;
      }
      //case-2 Option added
      const check2 = NoFromHasToHasOptions(item, params);
      if(check2){
        changeType = check2.changeType;
        isFound = check2.isFound;
      }
      //case-3 Option Removed
      const check3 = NoToHasFromHasOptions(item, params);
      if(check3){
        changeType = check3.changeType;
        isFound = check3.isFound;
      }
      //case-4 Is optional property modified
      const check4 = HasFromHasToHasProperties(item, params);
      if(check4){
        changeType = check4.changeType;
        isFound = check4.isFound;
      }
    });
    

    diffResponse.removed.forEach(function(removedItem){
      const check5 = RemovedItemFeatureFamilyCode(removedItem, params);
      if(check5){
        changeType = check5.changeType;
        isFound = check5.isFound;
      }
    });

    return GetTitleTooltip(isFound, changeType, t);
  }
  catch (error) { 
      return <></>
  }  
}
function dataGridCircularProgress( circularProgress:boolean ) {
  return circularProgress ? <div className = 'circularProgressBar'><CircularProgress/> </div>: <></>
}
