import http from "./http-common";

class IcaDataService {
  

  getAll(url:string) {
    return http.get(url);
  }

  getByParam(url:string, param:string) {        
    return http.get(`${url}${param}`);
  }

  create(url:string, data:any) {
    
    return http.post(url, data);
  }

  clone(url:string) {
    
    return http.post(url);
  }

  post(url:string, data:any) {
    
    return http.post(url, data);
  }


  update(url:string, id:any, data:any) {
    return http.put(`${url}/${id}`, data);
  }

  findByTitle(url:any, title:any) {
    return http.get(`${url}=${title}`);
  }
}

export default new IcaDataService();