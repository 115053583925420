import { AlertColor, Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Paper, PaperProps, Tab, TextField, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from '../GlobalSnackBar';
import {
  addConfigurationToSession, 
  checkConfigurationById,
  getConfigurationFromSession
} from '../../utility/SessionUtil';
import { SearchConfigurationApi } from '../../api/IcaApi';
import { ConfigurationModel } from '../../data/SessionModel';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DetailsPageStyle } from '../../../style/Styles'
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ProductImageGallery from '../Layouts/ProductImageGallery';

export default function ActionPanel( {setConfigurationsDataFromSession, isDataGridRowEmpty, dataGridRows, isFromLandingPage} ) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [configurationId, setConfigurationId] = useState( '' );
  const { setSnack } = useContext( SnackbarContext );
  const [disableValidateAllButton, setDisableValidateAllButton] = useState( false ); 
  const [disableHomeIcon, setDisableHomeIcon] = useState( false ); 
  const [TabValue, setTabValue] = React.useState<string|null>( 'SalesText' );
  const urlParameters = useParams();  
  const configState = useLocation();
  urlParameters.configurationId = configState?.state?.configId;
  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');  
  useEffect( () => {
    enableDisableValidateAllButton(isFromLandingPage, urlParameters, setDisableValidateAllButton, setProductId, setProductName, dataGridRows);
  }, [urlParameters, disableValidateAllButton] );

  
  //ImageDialog
  const [open, setOpen] = React.useState( false );

  const handleImageDialogClickOpen = () => {
    setOpen( true );
  };

  const handleClose = () => {
    setOpen( false );
  };

  const handleChange = ( ...params:[React.SyntheticEvent, string] ) => {
    setTabValue( params[1] );
  };

  function PaperComponent( props: PaperProps ) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={ '[class*="MuiDialogContent-root"]' }
      >
        <Paper { ...props } />
      </Draggable>
    );
  }
    
  const handleSearch = () => {
    //check if config exists
    if( checkConfigurationById( configurationId ) ) {
      setSnack( { severityColor:'error' as AlertColor, message: t( 'message.configurationExists' ), open: true} )
      return;
    }
    const searchFormData = {
      'configurationId': String(configurationId),
      'validate': true
    };
    SearchConfigurationApi(searchFormData)
    .then(function(response){
        readConfigurationResponseFunc(response, configurationId, setConfigurationsDataFromSession, setSnack, t);
    }).catch( e =>{
      console.error( e );
      setSnack( { severityColor:'error' as AlertColor,message: t( 'message.error' ), open: true} );
    } );
    
  }
  

  const gotoHome = ()=>{
    const windowUrl = window.location.pathname.split( '/' )[1]
    navigate( '/' + windowUrl );
  }


  useEffect( () => {
    handleDisableHomeIcon(isFromLandingPage, setDisableHomeIcon)
  }, [] );

  return(
    <Grid container>  
      <Grid xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } >
        <Box style={ DetailsPageStyle.productName } >
          <Tooltip title={ t( 'tooltip.home' ) }>
            <IconButton sx={ { p: '0.8rem' } } aria-label="home" disabled={ disableHomeIcon } onClick={ gotoHome }>
              <HomeIcon />
            </IconButton>
          </Tooltip>
          {!isFromLandingPage ? <Grid>{productName + ' (' + productId + ')'}
            <Tooltip title={ t( 'tooltip.productInfo' ) }>
              <IconButton sx={ { p: '0.8rem' } } aria-label="productInfo" onClick={ handleImageDialogClickOpen }>
                <InfoIcon style={ {color:'#1474A4'} }/>
              </IconButton>
            </Tooltip>   
          </Grid> : <></>}
        </Box>
        <Box className="actionBarFlex">
          { isFromLandingPage ? <TextField
            sx={ { m:1, width: 400, display: 'flex'} }
            placeholder= { t( 'button.configurations' ) }
            type="text"
            InputProps={ {
              endAdornment: 
              <InputAdornment position="end">
                <IconButton aria-label="search" onClick={ handleSearch }>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            } }
            variant="standard"
            onChange={ e => setConfigurationId( e.target.value ) }
            onKeyPress= { ( e ) => {
              if ( e.key === 'Enter' ) {
                e.preventDefault();
                handleSearch()
              }
            } }
          /> : <></>}
          
          <Dialog
            className="common-dialog-style infoDialogStyle"
            open={ open }
            onClose={ handleClose }
            PaperProps={ {className:'paper'} } 
            PaperComponent={ PaperComponent } 
            aria-labelledby="draggable-dialog-title"
            data-testid = "productInfoDialogBox"
          >
            <DialogTitle className="header title">
              <div className="infoDialogTitle">{productName + ' (' + productId + ')'}</div> 
            </DialogTitle>
            <DialogContent>
              <IconButton className="closeIcon"
                aria-label="close"
                onClick={ handleClose }
              >
                <CloseIcon />
              </IconButton>
              <TabContext value={ TabValue || 'SalesText' }>
                <Box className="dialog-box">
                  <TabList onChange={ handleChange } >                    
                    <Tab className="dialog-tab" 
                      value="SalesText" label={ t( 'label.salesText' ) }
                    />
                    <Tab className="dialog-tab"
                      value="ProductImages" label={ t( 'label.productImages' ) }
                    />
                  </TabList>
                </Box>
                <TabPanel className="dialog-panel" value="ProductImages" >
                  <ProductImageGallery/>
                </TabPanel>
              </TabContext>
            </DialogContent>
          </Dialog>
        </Box>
      </Grid>
    </Grid>
  )
}

function enableDisableValidateAllButton(isFromLandingPage : any, urlParameters: any, setDisableValidateAllButton: any, setProductId: any, setProductName: any, dataGridRows: any)
  {
    if(!isFromLandingPage && urlParameters?.configurationId)
    {
      setDisableValidateAllButton(true);
      const configData = getConfigurationFromSession(urlParameters.configurationId)
      if(configData != null)
      {
        setProductId(configData.productId);
        setProductName(configData.productName);
      }
    }    
    else if(dataGridRows?.length == 0 || dataGridRows.configurationId == '')
    {
      setDisableValidateAllButton(true);
    }
    else
    {
      setDisableValidateAllButton(false);
    }
  }

  
  function readConfigurationResponseFunc(response:any, configurationId:any, setConfigurationsDataFromSession:any, setSnack:any, t:any)
  {
    if ( response.status === 200 ) {
      const pmDetails =  response.data;

      var _config = new ConfigurationModel( configurationId.trim(), t( 'actionTypes.search' ), pmDetails );
      _config.isProcessing = false;
      addConfigurationToSession( _config );

      setConfigurationsDataFromSession();
      setSnack( { severityColor:'success' as AlertColor, message: t( 'message.configuration' ) + ' ' + t( 'message.readSuccess' ), open:true} )
    } else if ( response.status === 400 ||  response.status === 404){
      setSnack( { severityColor:'error' as AlertColor, message: t( 'message.readError' ), open: true} )
    }else{
      setSnack( { severityColor:'error' as AlertColor, message: t( 'message.error' ), open: true} )
    }
  }

  const handleDisableHomeIcon = (isFromLandingPage:any, setDisableHomeIcon:any) =>{
    if( isFromLandingPage ) {
      setDisableHomeIcon( true )
    }
  }