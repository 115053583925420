import React, { useContext, useState } from 'react';
import {
  DataGrid as MuiDataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridRowParams  
} from '@mui/x-data-grid';
import { Avatar, styled, Box, Stack, Link, AlertColor } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { formatDateString } from '../../utility/SessionUtil';
import IconButton from '@mui/material/IconButton';
import { CloneConfigurationApi } from '../../api/IcaApi';
import Tooltip from '@mui/material/Tooltip';
import { settings } from '../../settings';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from '../GlobalSnackBar';
import LinearProgress from '@mui/material/LinearProgress';
import {EUrlParams} from '../../data/Constants';

const DataGrid = styled( MuiDataGrid )( () => ( {
  '& .MuiDataGrid-columnHeaderTitle': { fontSize: '1.25rem', fontWeight: 'normal'},
  '& .MuiDataGrid-cell': { fontSize: 14 }
} ) );

export default function DataTableGrid( { navigate, dataGridRows } ) {
  const t = useTranslation().t;
  const [linerProgress, setLinerProgress] = useState( false );
  const currentUrl = window.location.pathname.split( '/' );  
  const { setSnack } = useContext( SnackbarContext );
  const handleOnCellClick = ( currentSelection: GridCellParams ) => {
    const buttonField = currentSelection.field
    if ( buttonField != null || buttonField != undefined ) {
      switch ( buttonField ) {
        case 'Clone': {
          handleClone( currentSelection );
          break;
        }
        case 'View': {
          handleView( currentSelection );
          break;
        }
        default: {
          break;
        }

      }
    }
  }

  const gridColumns = () => {
    return [
      {
        field: 'productId', headerName: t( 'label.product' ), flex: 1.5, editable: false,
        headerClassName: 'lastcolumnSeparator', minWidth:150,
        renderCell: ( params: GridRowParams ) => {
          return params.row.productId + " (" + params.row.productName + ")"
        }
      },
      {
        field: 'configurationId', headerName: t( 'label.configurationId' ), flex: 2, editable: false,
        headerClassName: 'lastcolumnSeparator', minWidth:150,
        renderCell: ( params: GridRowParams ) => {
          return configurationIdColumn( handleClone, params );
        },
      },
      {
        field: 'country', headerName: t( 'label.country' ), flex: 1, editable: false,
        headerClassName: 'lastcolumnSeparator',
        renderCell: ( params: GridRowParams ) => {
          return params.row.countryName + " (" + params.row.countryCode + ")"
        },
      },
      {
        field: 'createdOn', headerName: t( 'label.createdOn' ), flex: 1, editable: false,
        headerClassName: 'lastcolumnSeparator', minWidth:150,
        renderCell: ( params: GridRowParams ) => {
          return formatDateString(params.row.createdOn) + t('label.timeZone'); 
        },
      },
      {
        field: 'modifiedOn', headerName: t( 'label.modifiedOn' ), flex: 1, editable: false,
        headerClassName: 'lastcolumnSeparator', minWidth:150,
        renderCell: ( params: GridRowParams ) => {
          return formatDateString(params.row.modifiedOn) + t('label.timeZone');
        },
      },
      {
        field: 'isValid', headerName: t( 'label.validate' ), flex: 0.5,
        headerClassName: 'lastcolumnSeparator', minWidth:140,
        renderCell: ( params: GridRowParams ) => {
          return dataGridValidateColumn( params, t( 'tooltip.valid' ), t( 'tooltip.invalid' ) );
        },
      },
      {
        field: 'View', type: 'actions', headerName: t( 'label.view' ), flex: 0.5,
        headerClassName: 'lastcolumnSeparator', minWidth:70,
        getActions: () => [
          <Tooltip title={ t( 'tooltip.view' ) }>
            <GridActionsCellItem
              icon={ <Avatar sx={ { width: 30, height: 30, backgroundColor: '#5e5c5c !important' } }><PreviewIcon /> </Avatar> }
              label={ t( 'tooltip.view' ) }
            />
          </Tooltip>
        ]
      }
    ];
  }

  const handleClone = ( currentSelection: GridCellParams ) => {
    setLinerProgress( true );
    
    const bodyFormData = {
      'configurationId': String( currentSelection.row.configurationId ),
      'state': '2'
    };  

    CloneConfigurationApi( bodyFormData ).then( function ( response ) {
      if ( response.status == 200 ) {
        let queryParams = `${EUrlParams.ConfigurationId}=` + response.data + `&${EUrlParams.Scope}=` + settings.ApiKey;
        const url = settings.ConfiguratorApiEndpoint + `/?${queryParams}`;
        window.open( url, "_blank" );
      } else{        
        console.warn( t( 'message.error' ) + " " + response.status );
        setSnack( { severityColor:'error' as AlertColor, message: t( 'message.error' ) + response.status, open: true} )
      }
      setLinerProgress( false );
    } ).catch( e =>{
      console.error( e );
      commonErrorSnack();
    } );
  }

  const handleView = ( currentSelection: GridCellParams ) => {
    const configId = currentSelection.row.configurationId;
    const productId = currentSelection.row.productId;
    const defaultPageUrl = '/' + currentUrl[1] + '/' + productId + '/';
    navigate( defaultPageUrl + 'details',{state : {configId:configId}} );
  }

  function commonErrorSnack() {
    setLinerProgress( false );
    setSnack( { severityColor:'error' as AlertColor,message: t( 'message.error' ), open: true} );
  }

  const configurationsData = dataGridRows;
  const dataGridColumns = gridColumns();  
  const   dataGridComponent = {
    width: '100%',    
    height:window.innerHeight,
    display:'flow-root',
    overflowX: 'hidden',
    overflowY: 'auto',    
  };
  return (
    <Box sx={ dataGridComponent }>
      {dataGridLinerProgress( linerProgress )}
      <DataGrid
        rows={ configurationsData }
        columns={ dataGridColumns }       
        onCellClick={ handleOnCellClick }
        rowHeight={ 36 }
        autoHeight={ true }
        hideFooter={ true }
        headerHeight={ 36 }
        disableColumnMenu
        initialState={ {
          sorting: {
            sortModel: [{ field: 'timeStamp', sort: 'desc'}],
          }
        } }
        components={ {
          NoRowsOverlay: () =>
            <Stack height="100%" alignItems="center" justifyContent="center">
              No rows
            </Stack>
          ,
          NoResultsOverlay: () =>
            <Stack height="100%" alignItems="center" justifyContent="center">
              Local filter returns no result
            </Stack>
        } }
        sx={ {
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#eeeeee",
            color: "#000000"
          },
          "& .MuiDataGrid-columnSeparator":{
            display:"none"
          },
          boxShadow: 2
        } }
      />
    </Box>
  );
}

function configurationIdColumn( handleClone:object, params:GridRowParams ) {
  return <Link onClick={ ()=> { handleClone( params ) } } style= { {cursor:'pointer'} }> {params.row.configurationId} </Link>;        
}

function dataGridValidateColumn( params:GridRowParams, tValid:string, tInvalid:string) {
  return <> {params.row.isValid ?
    <Tooltip title={ tValid }>
      <IconButton><DoneIcon style={ { color: 'green' } } fontSize="large" />
      </IconButton>
    </Tooltip>
    : <Tooltip title={ tInvalid }>
      <IconButton><ClearIcon style={ { color: 'red' } } fontSize="large" />
      </IconButton>
    </Tooltip>}
    
    </>
}

function dataGridLinerProgress( linerProgress:boolean ) {
  return linerProgress ? <LinearProgress/> : <></>
}