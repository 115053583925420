import React,{useEffect} from 'react';
import { debugMode } from './services/debugHelper';
import { GlobalSnackBar } from './components/GlobalSnackBar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
function App() {
  useEffect(() => {    
    debugMode();
  }, []);

  return (
    <div className="App">
      <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <GlobalSnackBar/>
      </Box>
    </div>
  );
}

export default App;
