const getSettings = async () => {
  return fetch("../../public/app.settings.json").then((response) =>
    response.json()
  );
};
const debugSetting = await getSettings();
export const debugMode = () => {
  if (debugSetting.DeveloperMode) {
    console.log.bind(window.console);
  } else {
    console.log = () => null;
  }
};
