import React from 'react';
import { UserPanel } from './UserPanel';
import { useAuth } from 'oidc-react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
/**
 * Renders a button for login and logout functionality
 * @param {IHeaderLoginProps} props the props required by the component
 * @returns {JSX.Element} the user menu component
 */
 export const HeaderLogin = ( {setAuthState, mode} ) => {
    if ( mode.Developer_Mode ) {
      return <Button color="inherit">{'LOCAL DEVELOPER'}</Button>;
    }
    const {t} = useTranslation();
    const auth = useAuth();
    const isLoggedin = auth && auth.userData;
    const userName = `${auth.userData?.profile.name}`;
  
    return isLoggedin
      ? <UserPanel user={ userName } onLogout={ ()=>{
        setAuthState && setAuthState( 'logout' );
        auth.signOut();
      } }
      />
      : <Button color="inherit" onClick={ () => {
        setAuthState && setAuthState( 'login' );
        auth.signIn();
      } }
      >{t( 'button.login' )}</Button>;
  };
