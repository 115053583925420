import { Box, Grid, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ReadProductImageApi } from '../../api/IcaApi';
import CircularProgress from '@mui/material/CircularProgress';

export default function ProductImageGallery( ) {
  const productCode = window.location.pathname.split( '/' )[2]; 
  const [readImageResponse, setreadImageResponse] = React.useState([]); 
  const [productImageData, setProductImageData] = React.useState([]);
  const [circularProgress, setCircularProgress] = useState( false );
  
  const [slideNumber, setSlideNumber] = useState( 0 );

  const handleOpenImage = ( index ) =>{
    setSlideNumber( index )
  }
  const loadProductImages = (responseData) =>{
    setreadImageResponse(responseData);
    const ProdcutImageJson = JSON.parse( readImageResponse );
      const productImg = ProdcutImageJson.productImages.filter( ( p )=>{
        return p.materialCode === productCode
      } ).map( ( pm )=>pm.materialImages );

      setProductImageData(productImg[0]);
  }
  const getProductImages= () => {
    setCircularProgress(true);
      ReadProductImageApi(productCode)
      .then(function(response){
        if(response.status == 200){
          setCircularProgress(false);
          loadProductImages(response.data);
        }
      }).catch( e =>{
        console.error( e );
        setCircularProgress(false);
      });
    
  }
  useEffect( ()=>{   
    getProductImages();
  },[readImageResponse])
  
  const sliderSettings = {
    dots: true,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: 
      <div>
        <IconButton className="next-slick-arrow" sx={ {marginTop:'-14px'} }>
          <ChevronRightIcon fontSize="large"/>
        </IconButton>
      </div>
    ,
    prevArrow: 
      <div>
        <IconButton className="prev-slick-arrow" sx={ {marginTop:'-14px'} }>
          <ChevronLeftIcon fontSize="large"/>
        </IconButton>
      </div>
    
  }
  {productLoadCircularProgress(circularProgress)}
    if(productImageData.length > 0){
        return <Grid container sx={ {flexDirection: 'column'} } className="imageGrid">
          <Box className="fullScreenImage">
          <img src={productImageData.length>0 ? productImageData[slideNumber].url : ''}/>
          </Box>
          <Box className="slider-wrapper"> 
            <Slider { ...sliderSettings }>
            { productImageData.length >0 ? productImageData.map( ( slide, index )=>
                <div key={ index } className="slick-slide" onClick={ ()=>handleOpenImage( index ) }>
                  <img src={ slide?.url }/>
                </div>
              ): <></>}
            </Slider>
          </Box>
      </Grid> 
    }else{
      return <div>
      <img src="public\ProductImageNotFound.png" style={ {height:'90%', width:'90%', margin:'5px'}}/>
    </div>
    }
}
function productLoadCircularProgress( circularProgress:boolean ) {
  return circularProgress ? <div className = 'circularProgressBar'><CircularProgress/> </div>: <></>
}