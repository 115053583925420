import axios from "axios";
import { settings } from '../settings';
import { oidcDataKey } from '../data/Constants';

const baseUrl = settings.ConfiguratorApiEndpoint;
let axiosInstance =  axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8;',
    'Authorization': getOdicToken(),
    'context' : settings.ApiKey
  }
});

// Add interceptor to add Authorization Token
axiosInstance.interceptors.request.use(function (config) {    
    config.headers.Authorization =  getOdicToken();
    return config;
});

export default axiosInstance;

function getOdicToken ()
{
  const sessionOidcKey = window.sessionStorage.getItem(oidcDataKey);
  const sessionOidcToken = window.sessionStorage.getItem(sessionOidcKey);
  if(sessionOidcKey == null || sessionOidcKey == '' || sessionOidcToken == null || sessionOidcToken == '')
  {
    return '';
  }
  return 'Bearer ' + JSON.parse(sessionOidcToken).access_token;
}
